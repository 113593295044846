import React, { useEffect } from "react"
import MenuClose from "../images/svgs/menu-close.svg"
import content from "../content"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import _ from "lodash"

const TermsModal = (props) => {
  let targetElement
  const insideTextModal = []
  const modalInfo = _.get(content, ["footer", "legal", props.modalInfo])
  const title = _.get(modalInfo, "title") || ""
  const text = _.get(modalInfo, "text") || ""
  const isMobileIosSafari =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("iPhone") != -1

  useEffect(() => {
    targetElement = document.querySelector("#terms-text-container")

    if (isMobileIosSafari) {
      document
        .querySelectorAll(`#terms-text-container *`)
        .forEach((node) => insideTextModal.push(node))
      window.addEventListener("touchmove", handleTouchMove, { passive: false })
    } else {
      disableBodyScroll(targetElement)
    }
  })

  const handleTouchMove = (e) => {
    if (
      !(e.target.id === targetElement.id || insideTextModal.includes(e.target))
    ) {
      e.preventDefault()
    }
  }

  const handleModalClose = (e) => {
    if (
      _.includes(["terms-modal-container", "close-button"], e.target.className)
    ) {
      if (isMobileIosSafari) {
        window.removeEventListener("touchmove", handleTouchMove, {
          passive: false,
        })
      } else {
        enableBodyScroll(targetElement)
      }
      props.setModalInfo(false)
    }
  }

  return (
    <div
      id="terms-modal"
      className="terms-modal-container"
      onClick={handleModalClose}
    >
      <div className="text-container" id="terms-text-container">
        <h3>{title}</h3>
        {text.split("\n").map((item, i) => (
          <p key={`title-${i}`}>{item}</p>
        ))}
      </div>
      <img className="close-button" src={MenuClose} />
    </div>
  )
}

export default TermsModal
